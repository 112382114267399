export const CREATE_POLL_REQUEST = 'CREATE_POLL_REQUEST';
export const CREATE_POLL_SUCCESS = 'CREATE_POLL_SUCCESS';
export const CREATE_POLL_FAILURE = 'CREATE_POLL_FAILURE';

export const GET_POLL_REQUEST = 'GET_POLL_REQUEST';
export const GET_POLL_SUCCESS = 'GET_POLL_SUCCESS';
export const GET_POLL_FAILURE = 'GET_POLL_FAILURE';

export const DELETE_POLL_REQUEST = 'DELETE_POLL_REQUEST';
export const DELETE_POLL_SUCCESS = 'DELETE_POLL_SUCCESS';
export const DELETE_POLL_FAILURE = 'DELETE_POLL_FAILURE';

export const EDIT_POLL_REQUEST = 'EDIT_POLL_REQUEST';
export const EDIT_POLL_SUCCESS = 'EDIT_POLL_SUCCESS';
export const EDIT_POLL_FAILURE = 'EDIT_POLL_FAILURE';

export const UPDATE_POLL = 'UPDATE_POLL';


export const VOTE_POLL_REQUEST = 'VOTE_POLL_REQUEST';
export const VOTE_POLL_SUCCESS = 'VOTE_POLL_SUCCESS';
export const VOTE_POLL_FAILURE = 'VOTE_POLL_FAILURE';

export const SELECT_OPTION = 'SELECT_OPTION';

export const DISABLE_VOTING = 'DISABLE_VOTING';

export const FLUSH_POLL = 'FLUSH_POLL';